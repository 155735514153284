export const store = {
  store: "Store",
  exportData: "Export data",
  storeTransactions: "Store transactions",
  allItems: "All items",
  classroomStoreOpen: "Classroom store open",
  classroomStoreClosed: "ClassRoom store closed",
  whenTheClassroomStoreIsOpen:
    "When the Classroom store is open students can make purchases from the store. When the store is closed, students cannot view items or make purchases.",
  addItem: "Add item",
  searchForItem: "Search for item",
  item: "Item",
  value: "Value",
  quantity: "Quantity",
  classroomHasBeenOpened: "Classroom store has been opened",
  classroomHasBeenClosed: "Classroom store has been closed",
  addSuggestedItems: "Add suggested items",
  youDoNotHaveAnyItemsYet: "You don’t have any items yet",
  youCanChooseFromOurSuggestedStoreItems:
    "You can choose from our suggested store items and customize them to fit your classroom, or add your own items form scratch!",
  sortBy: "Sort by",
  editItem: "Edit item",
  deleteItem: "Delete item",
  hideItemInStore: "Hide the item in the store",
  showItemInStore: "Show the item in the store",
  nameAscending: "Name ascending",
  nameDescending: "Name descending",
  valueAscending: "Value ascending",
  valueDescending: "Value descending",
  studentAscending: "Student ascending",
  studentDescending: "Student descending",
  itemAscending: "Item ascending",
  itemDescending: "Item descending",
  quantityAscending: "Quantity ascending",
  quantityDescending: "Quantity descending",
  dateAscending: "Date ascending",
  dateDescending: "Date descending",
  name: "Name",
  itemsSelected_one: "{{count}} item selected",
  itemsSelected_other: "{{count}} items selected",
  outOfStock: "Out of stock",
  inStock: "In stock",
  delete: "Delete",
  areYouSureYouWantToDelete_one:
    "Are you sure you want to delete this ({{count}}) selected item?",
  areYouSureYouWantToDelete_other:
    "Are you sure you want to delete these ({{count}}) selected items?",
  thisCanNotBeRestored: "This can't be restored",
  selectedItemDeleted_one: "({{count}}) selected item has been deleted",
  selectedItemDeleted_other: "({{count}}) selected items have been deleted",
  selectedMarkedAsInStock_one:
    "({{count}}) selected item has been marked as in stock",
  selectedMarkedAsInStock_other:
    "({{count}}) selected items have been marked as in stock",
  selectedMarkedAsOutOfStock_one:
    "({{count}}) selected item has been marked as out of stock",
  selectedMarkedAsOutOfStock_other:
    "({{count}}) selected items have been marked as out of stock",
  itemHasBeenDeleted: "{{- name}} has been deleted",
  areYouSureYouWantToDelete: "Are you sure you want to delete this item?",
  itemMarkedAsInStock: "{{- name}} has been marked as in stock",
  itemMarkedAsOutOfStock: "{{- name}} has been marked as out of stock",
  itemTitle: "Item title",
  uploadPicture: "Upload picture",
  orSelectAvatar: "Or select avatar",
  itemHasBeenEditedSuccessfully: "Item has been edited successfully",
  itemHasBeenAddedSuccessfully: "Item has been added successfully",
  chooseItemAvatar: "Choose item avatar",
  suggestedItemsHaveBeenAdded: "Suggested items have been added",
  searchForTransaction: "Search for transaction",
  youDoNotHaveAnyTasksToDo: "You don't have any tasks to do",
  greatWorkStayingOnTop:
    "Great work staying on top of your to do list! Check back later for new student purchases.",
  student: "Student",
  date: "Date",
  transactionsSelected_one: "{{count}} transaction selected",
  transactionsSelected_other: "{{count}} transactions selected",
  transactionsAccepted_one: "{{count}} transaction accepted successfully",
  transactionsAccepted_other: "{{count}} transactions accepted successfully",
  transactionsRejected_one: "{{count}} transaction rejected successfully",
  transactionsRejected_other: "{{count}} transactions rejected successfully",
  dataHasBeenSuccessfullyExported: "Data has been successfully exported",
  maxOf500selectedTransactionsReached:
    "Max. of 500 selected transactions reached",
  remainingTransactions: "{{count}} remaining transactions",

  // suggested items
  tellJokeToClass: "Tell a joke to the class",
  brainBreakChoice: "Brain break choice",
  buyYourDesk: "Buy your desk",
  fiveMinutesExtraRecess: "5 minutes extra recess",
  stinkyFeetNoShoesInClass: "Stinky feet (no shoes in class)",
  lunchWithTeacher: "Lunch with teacher",
  pencil: "Pencil",
  smallTreasureBoxItem: "Small treasure box item",
  smallStoreItem: "Small store item",
  sitInTheTeachersChair: "Sit in the teacher's chair for day",
  eatInClass: "Eat in class",
  magicTrick: "Magic trick",
  homeworkPass: "Homework pass",
  pieTeacherInTheFace: "Pie teacher in the face",
  swapSeatsForTheDay: "Swap seats for the day",
  switchJobsWithaClassmateForTheDay: "Switch jobs with a classmate for the day",
  passToGoToTheFrontOfTheLunchLine: "Pass to go to the front of the lunch line",
  extraCreditPoints: "Extra credit points",
  freeDressCoupon: "Free dress coupon",
  specialLeadershipRole:
    "Special leadership role (Door greeter, errand runner, cafeteria leader, etc.) ",
  positivePhoneCallHome: "Positive phone call home",
  loadMore: "Load more",
  youCanSendUpToTransactions: "You can send up to 200 transactions",
  theLimitIsTransactionsAtOnce: "The limit is 200 transactions at once",
  upgradeToProOrSchoolToUnlockInventory:
    "Upgrade to Pro or School to unlock inventory",
  manageClassStore: "Manage Class Store",
  manageItems: "Manage Items",
}
