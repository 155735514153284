import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { ITeacherPostInput, ITeacherPostResponse } from "ts/interfaces/Teacher"

import {
  IChangePasswordArgs,
  IChooseSchoolDashboardArgs,
  IFinalizeArgs,
  ILoginTeacherArgs,
  IPostChangePassword,
  IPostChooseSchoolDashboard,
  IPostFinalize,
  IPostLoginTeacher,
  IPostTeacherHookArgs,
  IResponseChangePassword,
  IResponseFinalize,
  IResponseLoginTeacher,
  IResponseSelfUpdate,
  ISelfUpdateArgs,
  OptionalPostSelfUpdate,
} from "./teacher.types"

export const useMutationPostTeacher = (hookArgs?: IPostTeacherHookArgs) =>
  useMutation<
    AxiosResponse<ITeacherPostResponse>,
    AxiosError,
    ITeacherPostInput
  >(async (data) => {
    return await axiosInstance.post(Endpoint.TEACHER, data, {
      headers: hookArgs?.headers,
    })
  }, hookArgs?.options)

export const useMutationSelfUpdate = (args?: ISelfUpdateArgs) =>
  useMutation<
    AxiosResponse<IResponseSelfUpdate>,
    AxiosError,
    OptionalPostSelfUpdate
  >(async (data) => {
    return await axiosInstance.patch(Endpoint.TEACHER_ME, data)
  }, args?.options)

export const useMutationChangePassword = (args?: IChangePasswordArgs) =>
  useMutation<
    AxiosResponse<IResponseChangePassword>,
    AxiosError,
    IPostChangePassword
  >(async (data) => {
    return await axiosInstance.post(Endpoint.TEACHER_PASSWORD_CHANGE, data)
  }, args?.options)

export const useMutationLoginTeacher = (args?: ILoginTeacherArgs) =>
  useMutation<
    AxiosResponse<IResponseLoginTeacher>,
    AxiosError,
    IPostLoginTeacher
  >(async (data) => {
    return await axiosInstance.post(Endpoint.TEACHER_LOGIN_ACCESS_TOKEN, data)
  }, args?.options)

export const useMutationFinalize = (args?: IFinalizeArgs) =>
  useMutation<AxiosResponse<IResponseFinalize>, AxiosError, IPostFinalize>(
    async (data) => {
      return await axiosInstance.post(Endpoint.TEACHER_FINALIZE, data)
    },
    args?.options
  )

export const useMutationChooseSchoolDashboard = (
  args?: IChooseSchoolDashboardArgs
) =>
  useMutation<AxiosResponse<string>, AxiosError, IPostChooseSchoolDashboard>(
    async (data) => {
      return await axiosInstance.post(Endpoint.TEACHER_CHOOSE_SCHOOL, data)
    },
    args?.options
  )
