export const students = {
  youDoNotHaveAnyStudentsYet: "You don't have any students yet",
  addStudentsManuallyOrHaveStudents:
    "Add students manually or have students easily sign up with a class code!",
  addStudent: "Add student",
  searchForStudent: "Search for student",
  exportData: "Export Data",
  sendTransactionsToMoreThan:
    "Send transactions to more than one student by selecting them on the list",
  mathOn: "Math On",
  turnOnOrTurnOffMathOption:
    "Turn ON or turn OFF math option to more than one student by selecting them on the list",
  studentsInfo: "Student info",
  classCode: "Class code",
  dontWantStudentsToHaveToAddOrSubtractTheirTransactions:
    "Don't want students to have to add or subtract their transactions? Uncheck them from the list below.",
  sendBonuses: "Send bonuses",
  sendFines: "Send fines",
  sendPaychecks: "Send paychecks",
  sendExpenses: "Send expenses",
  studentsSelected_one: "{{count}} student selected",
  studentsSelected_other: "{{count}} students selected",
  numberOfStudentsSelected: "{{quantity}} students selected",
  student: "Student",
  job: "Job",
  balance: "Balance",
  checking: "Checking",
  pending: "Pending",
  savings: "Savings",
  showCheckingAndSavingsTotals: "Show checking & savings",
  hideCheckingAndSavingsTotals: "Hide checking & savings",
  delete: "Delete",
  currentBalance: "Current balance",
  sendTransaction: "Send transaction",
  goTo: "Go to",
  toEnterYourClassCode: "To enter your class code",
  addToClass: "Add to class",
  firstName: "First name",
  lastName: "Last name",
  userName: "User name",
  setPassword: "Set password",
  password: "Password",
  addAnotherStudent: "Add another student",
  areYoutSure: "Are you sure you want to close?",
  enteredInformationWillBeLost: "Entered information will be lost",
  value: "Value",
  studentFirst: "Student first name",
  studentLast: "Student last name",
  studentAscending: "Student ascending",
  studentDescending: "Student descending",
  studentLastAscending: "Student last name ascending",
  studentLastDescending: "Student last name descending",
  studentFirstAscending: "Student first name ascending",
  studentFirstDescending: "Student first name descending",
  valueAscending: "Value ascending",
  valueDescending: "Value descending",
  balanceAscending: "Balance ascending",
  balanceDescending: "Balance descending",
  checkingsAscending: "Checkings Ascending",
  checkingsDescending: "Checkings Descending",
  savingsAscending: "Savings Ascending",
  savingsDescending: "Savings Descending",
  noJobs: "No jobs",
  seeStudentsProfile: "See student's profile",
  transactionsSentSuccessfully: "Transactions sent successfully",
  selectUpTo20Transactions: "Select up to 20 transactions",
  selectAtLeaseOneTransaction: "Select at least one transaction",
  addNewStudent: "Add new student",
  searchForStudentNameOrAddNew: "Search for student name or add new",
  studentAddedSuccessfully: "Student added successfully",
  studentsAddedSuccessfully: "Students added successfully",
  userNamesHaveToBeUnique: "User names have to be unique",
  clickToFillOut: "Click to fill out",
  youNeedToAddAtLeastOneStudent: "You need to add at least one student",
  importStudents: "Import students",
  importStudentsDescription:
    "Upload an Excel file to import your students. Start by downloading our upload template <Link>here.</Link>",
  selectFileToUpload: "Select file to upload",
  listOfStudentsHasBeenImported: "List of students has been imported",
  processing: "Processing...",
  importFailed: "Import failed:",
  notAllowedDataOrEmptyCellsInRows: "Not allowed data, or empty cells in rows",
  importHasBeenCompleted: "Import has been completed",
  importUpdates: "Import updates",
  somethingWentWrong:
    "Something went wrong with the importing, refresh site to check errors",
  somethingWentWrongTryAgain: "Something went wrong, please try again later",
  resetBalance: "Reset balance",
  removeFromClass: "Remove from class",
  deleteStudent: "Delete student",
  areYouSureYouWantToRemoveStudentFromClass:
    "Are you sure you want to remove {{student}} from {{classname}}?",
  areYouSureYouWantToRemoveTheseStudentsFromTheClass:
    "Are you sure you want to remove these students from the class {{classname}}?",
  areYouSureYouWantToResetBalance:
    "Are you sure you want to reset {{student}}'s balance?",
  areYouSureYouWantToResetBalancesForClass:
    "Are you sure you want to reset balances for class {{classname}}?",
  areYouSureYouWantToDeleteStudent:
    "Are you sure you want to delete {{student}}?",
  areYouSureYouWantToDeleteStudents:
    "Are you sure you want to delete students?",
  balanceHasBeenSuccessfullyReset:
    "{{student}} balance has been successfully reset",
  studentsBalancesHasBeenSuccessfullyReset:
    "Students balance have been successfully reset",
  studentHasBeenSuccessfullyDeleted:
    "{{student}} has been successfully deleted",
  studentHasBeenSuccessfullyRemovedFromClass:
    "{{student}} has been successfully removed from class ",
  studentHaveBeenSuccessfullyDeleted: "Students have been successfully deleted",
  studentsHaveBeenSuccessfullyRemovedFromClass:
    "Students have been successfully removed from class ",
  thisActionCannotBeUndone:
    "This action cannot be undone. All student data will be permanently deleted for you and all other teachers.",
  youWillRemoveAllOfStudentsInThisClassExcept:
    "You will remove all of students in this class except:",
  youWillRemoveTheFollowingStudents: "You will remove the following students:",
  youWillRemoveAllOfStudentsFromClass:
    "You will remove all of students from class",
  youWillDeleteAllOfStudentsInThisClassExcept:
    "You will delete all of students in this class except:",
  youWillDeleteTheFollowingStudents: "You will delete the following students:",
  youWillDeleteAllOfStudentsFromClass:
    "You will delete all of students from class",
  youWillResetBalanceAllOfStudentsInThisClassExcept:
    "You will reset balance all of students in this class except:",
  youWillResetBalanceTheFollowingStudents:
    "You will reset balance for the following students:",
  youWillResetBalanceAllOfStudentsFromClass:
    "You will reset balance for all of students from class",
}
