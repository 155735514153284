export enum RoutePath {
  HOMEPAGE = "/",
  ACCOUNTS = "/accounts",
  PAYMENT_SUCCESSFUL = "/payment-successful",
  CLASS = "/class/:classId",
  CLASS_JOBS = "/class/:classId/jobs",
  CLASS_STORE = "/class/:classId/store",
  CLASS_BANKER = "/class/:classId/banker",
  CLASS_STORECLERK = "/class/:classId/storeClerk",
  CLASS_STORECLERK_ALL_ITEMS = "/class/:classId/storeClerk/all-items",
  CLASS_HR = "/class/:classId/hr-representative",
  CLASS_STORE_ALL_ITEMS = "/class/:classId/store/all-items",
  CLASS_SETUP = "/class/:classId/setup",
  CLASS_SETUP_BILLS = "/class/:classId/setup/expenses",
  CLASS_SETUP_BONUSES = "/class/:classId/setup/bonuses",
  CLASS_SETUP_FINES = "/class/:classId/setup/fines",
  CLASS_COTEACHERS = "/class/:classId/co-teachers",
  CLASS_STUDENT_HELPERS = "/class/:classId/student-helpers",
  STUDENT = "/student/:studentId",
  SIGNUP = "/sign-up",
  LOGIN = "/login",
  RESET_PASSWORD = "/reset_password",
  CHANGE_PASSWORD = "/change_password",
  PAGE404_NOT_FOUND = "/not_found",
  SETTINGS = "/settings",
  ALL_TRANSACTIONS = "/all-transactions",
  PAGE404 = "*",
}
